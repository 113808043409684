// Footer.js

import React from 'react';
import './style1.css';

const Footer = () => {
  return (
    <footer style={footerStyle}>
      <div className="container">
        <div className="footer-content">
          <div className="footer-item">
            <h3>Contact</h3>
            <p>Email: alphaursu@gmail.com</p>
            <p>Phone: 03366426633</p>
          </div>
          <div className="footer-item">
            <h3>Address</h3>
            <p>Chak Shahzad, Islamabad</p>
            <p>Pakistan</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

const footerStyle = {
  backgroundColor: '#f2f2f2', // Light grey background color
  padding: '20px',
  color: '#333', // Text color
};

export default Footer;
