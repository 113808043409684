

import { faker } from '@faker-js/faker';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import photo1 from "../photos/smallbottlesingle.jpeg"
import photo2 from "../photos/largebottle1.jpeg"



// ----------------------------------------------------------------------

const twoProducts = [
  {
    id: faker.datatype.uuid(),
    cover: photo1,
    name: '0.5l Bottle',
    price: 200, // Change the price to 50 PKR
    colors: [], // Change the colors if needed
    status: 'new',
    size: 'small',
  },
  {
    id: faker.datatype.uuid(),
    cover: photo2,
    name: '19l Bottle',
    price: 220, // Change the price to 50 PKR
    priceSale: 1300,
    colors: [], // Change the colors if needed
    status: 'new',
    size: 'small',
  },
];



export default twoProducts;
