import { Helmet } from 'react-helmet-async';
import { useTheme } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'; // Import React and useState
import coverPhoto from "../photos/cover.jpeg";
import specialOffer from "../photos/specialoffer1.jpeg";
import nutritionfacts from "../photos/nutritionfacts.jpeg";
import manufacturingVideo from "../photos/manufacturing.mp4";
import bulk from "../photos/smallbottlebulk.jpeg";

export default function DashboardAppPage() {
  const theme = useTheme();
  const [animationCompleted, setAnimationCompleted] = useState(false);

  const mediaStyles = {
    maxWidth: '100%',
    height: 'auto',
    maxHeight: '450px', // Set a maximum height for images and videos
  };

  const flexContainerStyles = {
    display: 'flex',
    flexDirection: 'row', // Display "Manufacturing" and "Special Offer" side by side
    justifyContent: 'center',
  };

  // Keyframes animation for fading in
  const fadeInAnimation = {
    opacity: animationCompleted ? 1 : 0,
    transform: animationCompleted ? 'translateY(0)' : 'translateY(20px)',
    transition: 'opacity 0.5s ease, transform 0.5s ease',
  };

  // Function to set animationCompleted to true after a delay
  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimationCompleted(true);
    }, 1000); // Delay the animation completion by 1000 milliseconds (1 second)
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Helmet>
        <title>Alphaursu</title>
      </Helmet>

      <Container maxWidth="xl">
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {/* Cover Photo Section */}
          <div style={{ padding: '20px', textAlign: 'center' }}>
            <img src={coverPhoto} alt="Cover" style={{ ...mediaStyles, ...fadeInAnimation }} />
          </div>

          {/* Flex Container for "Manufacturing" and "Special Offer" */}
          <div style={flexContainerStyles}>
            {/* Manufacturing Video Section */}

            {/* Special Offer Section */}
            <div style={{ padding: '20px', textAlign: 'center', ...fadeInAnimation }}>
              <h3>Our Products</h3>
              <img src={bulk} alt="Offer" style={mediaStyles} />
             
            </div>
          </div>

          {/* Nutritional Facts Section */}
          <div style={{ padding: '20px', textAlign: 'center', ...fadeInAnimation }}>
            <h3>Nutritional Facts</h3>
            <img src={nutritionfacts} alt="Nutritional Facts" style={mediaStyles} />
          </div>
        </div>
      </Container>
    </>
  );
}
