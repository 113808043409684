import PropTypes from 'prop-types';
import { Box, Card, Link, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { fCurrency } from '../../../utils/formatNumber';
import Label from '../../../components/label';
import { ColorPreview } from '../../../components/color-utils';

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

ShopProductCard.propTypes = {
  product: PropTypes.object,
};

export default function ShopProductCard({ product }) {
  const { name, cover, price, colors, status, priceSale, size } = product;

  // Define a default value for bottle size string
  let bottleSizeString = '';

  if (size === 'small') {
    bottleSizeString = ' (Per Pett) ';
  }

  return (
    <Card>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        {status && (
          <Label
            variant="filled"
            color={(status === 'sale' && 'error') || 'info'}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase',
            }}
          >
            {status}
          </Label>
        )}
        <StyledProductImg alt={name} src={cover} />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Link color="inherit" underline="hover">
          <Typography variant="subtitle2" noWrap>
            {name}
          </Typography>
        </Link>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <ColorPreview colors={colors} />
          <Typography variant="subtitle1">
            {fCurrency(price)}
            {!priceSale && (
  <span style={{ fontSize: 'smaller', color: 'grey' }}>{bottleSizeString}</span>
)}
{priceSale && (
  <>
 
    <span style={{ fontSize: 'smaller', color: 'grey' }}> {' (Refill) '}
    {' + '}</span>
    <span style={{ fontSize: 'smaller', color: 'grey' }}>{fCurrency(priceSale)}</span>
    <span style={{ fontSize: 'smaller', color: 'grey' }}> Security charges</span>
  </>
)}

          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
