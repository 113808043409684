import { Helmet } from 'react-helmet-async';
import { useTheme } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import manufacturingVideo from "../photos/manufacturing.mp4";
import newmanufacturingVideo from "../photos/newmanufacturing.MP4";

export default function DashboardAppPage() {
  const theme = useTheme();
  const [animationCompleted, setAnimationCompleted] = useState(false);

  const mediaStyles = {
    maxWidth: '100%', // Adjust the width as needed
    height: 'auto',
    maxHeight: '650px', // Adjust the maximum height as needed
    borderRadius: '15px', // Add rounded corners
  };

  const paragraphStyles = {
    fontSize: '20px', // Adjust the font size as needed
    fontWeight: 'lighter', // Make the text thinner
  };

  // Keyframes animation for fading in
  const fadeInAnimation = {
    opacity: animationCompleted ? 1 : 0,
    transform: animationCompleted ? 'translateY(0)' : 'translateY(20px)',
    transition: 'opacity 0.5s ease, transform 0.5s ease',
  };

  // Function to set animationCompleted to true after a delay
  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimationCompleted(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Helmet>
        <title>Alphaursu: Manufacturing</title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" style={{ textAlign: 'left', margin: '20px 0' }}>
          <h3>Our Manufacturing</h3>
          <p style={paragraphStyles}>
            With a commitment to quality, these bottles are crafted using advanced techniques, ensuring durability and safety for consumers.
          </p>
        </Typography>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center', // Center horizontally
          }}
        >
          {/* Manufacturing Video Section */}
          <div style={{ padding: '20px', textAlign: 'center', ...fadeInAnimation }}>
            <video controls autoPlay style={{ ...mediaStyles }}>
              <source src={manufacturingVideo} type="video/mp4" />
              <track kind="captions" src="captions.vtt" label="English" />
              Your browser does not support the video tag.
            </video>
          </div>

          {/* Add space between videos (adjust width as needed) */}
          <div style={{ width: '140px' }} />
          
          {/* Special Offer Video Section */}
          <div style={{ padding: '20px', textAlign: 'center', ...fadeInAnimation }}>
            <video controls autoPlay style={{ ...mediaStyles }}>
              <source src={newmanufacturingVideo} type="video/mp4" />
              <track kind="captions" src="captions.vtt" label="English" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </Container>
    </>
  );
}
